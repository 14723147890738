import { useEffect } from 'react';

const useFormPersist = (name, _ref, _temp) => {
  const { watch, setValue } = _ref;
  const values = watch();

  useEffect(() => {
    let str = sessionStorage.getItem(name);

    if (str) {
      let _values = JSON.parse(str);

      Object.keys(_values).forEach(key => {
        setValue(key, _values[key]);
      });
    }
  }, [name, setValue]);

  useEffect(() => {
    sessionStorage.setItem(name, JSON.stringify(values));
  });

  return {
    clear: () => {
      return sessionStorage.removeItem(name);
    },
  };
};

export default useFormPersist;
